import React, { useEffect, useState } from 'react'

import styles from '../Dashboard.module.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import { firestore as db } from '../../firebase.js'

export default function SplitReviewModal(props) {
    const [splits, setSplits] = useState([])
    const setLoading = props.setLoading
    let payee = props.payee
    const updateCounter = props.updateCounter
    const setUpdateCounter = props.setUpdateCounter

    useEffect(() => {
        async function fetchData() {
            console.log(payee)
            if (payee) {
                setLoading(true)
                console.log("Inside if statement")
                let newSplits = []
                await Promise.all(payee.unapprovedSplits.map(async splitRef => {
                    let doc = await splitRef.get()
                    let split = { id: await doc.id, data: doc.data(), checked: false }
                    console.log(split)
                    if (split.data.refs.length > 0) {
                        newSplits.push(split)
                    }

                }))
                console.log(newSplits)
                setSplits(newSplits)
                setLoading(false)
            }


            // db.collection('projects').doc().collection
        }
        fetchData()
        return
    }, [payee, setLoading])

    console.log(payee)

    function handleChange(index) {
        console.log("Clicked!" + index)
        console.log(splits[index])
        let newSplits = splits
        splits[index].checked = !splits[index].checked
        setSplits(newSplits)
    }

    function handleSubmit(e) {
        e.preventDefault()
        let toApproveSplits = []
        toApproveSplits = splits.filter(split => {
            return split.checked
        })


        toApproveSplits.map(async split => {
            console.log(split)
            async function approveSplits() {
                try {
                    //SETS "approved" FLAG TO TRUE ON SPLIT PAYEE
                    split.data.refs.map(async ref => {
                        let snap = await ref.ref.get()
                        let snapData = snap.data()
                        let newSplitSnap = await snapData.splitRef.get()
                        let newSplit = newSplitSnap.data()
                        console.log("We're Here!!")
                        console.log(newSplit)
                        newSplit.payees.map((splitPayee, index) => {
                            if (splitPayee.paypalEmail === payee.paypalEmail) {
                                newSplit.payees[index].approved = true
                            }
                            return null
                        })
                        console.log(newSplit)
                        console.log("We're still here!")
                        await snapData.splitRef.set(newSplit)

                        console.log(payee)
                    })
                    //REMOVES SPLIT FROM unapprovedSplits ARRAY ON PAYEE
                    let payeeRef = db.collection('payees').doc(payee.paypalEmail)
                    let payeeSnap = await payeeRef.get()
                    let payeeData = payeeSnap.data()
                    let unApproved = payeeData.unapprovedSplits
                    let remainingSplits = []
                    remainingSplits = unApproved.filter(unapprovedSplit => {
                        return unapprovedSplit.id !== split.id
                    })
                    console.log(remainingSplits)
                    payee.unapprovedSplits = remainingSplits
                    await payeeRef.set(payee)

                    //TRIGGERS RERENDER AND STATE UPDATE
                    let newUpdateCounter = updateCounter + 1
                    setUpdateCounter(newUpdateCounter)
                    props.onHide()
                } catch (error) {
                    console.error("There was a problem approving splits " + error)
                    //alert("There was an error. Please try again")
                }

            }

            approveSplits()

            setUpdateCounter()
        })
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    ><Modal.Header closeButton>
            <Modal.Title>REVIEW SPLITS</Modal.Title>

        </Modal.Header>
        <Modal.Body>
            <div><strong>{payee?.paypalEmail}</strong></div>
            <div className={styles['splits-review-card-container']}>
                {splits ? splits.map((split, index) => {
                    console.log(split.checked)
                    return <Card className={styles['split-review-card']}>
                        <div>{split && split.data ? split.data.refs.map(ref => {
                            return <div>Type: <strong>{ref.type}</strong> ID: <strong>{ref.ref.id}</strong></div>
                        }) : null}</div>
                        <Table bordered size="sm">
                            <tbody>
                                {split && split.data ? split.data.payees.map(payee => {
                                    console.log(split)
                                    return <tr>
                                        <td>{payee.paypalEmail}</td>
                                        <td>{payee.percent}%</td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </Table>
                        <div className={styles['approve-split-check']}>Index: {index} <Form.Check value={split.checked} onChange={() => handleChange(index)} label="Approve split" /></div>
                    </Card>
                }) : null}
            </div>
            <div>
                <Button onClick={(e) => handleSubmit(e)} className={styles['fando-button']}>SAVE</Button>
            </div>
        </Modal.Body>

    </Modal>
}
