import React, { useEffect, useState } from 'react'
import { firestore as db } from '../firebase.js'
import Table from 'react-bootstrap/Table'

function OrdersTable({ user }) {
    const [orders, setOrders] = useState();


    let orderDisplay = '';

    useEffect(() => {
        let ordersArray = []
        db.collection("orders").where('user', '==', user.uid).get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                ordersArray.push(doc.data())
                console.log(doc.id, " => ", doc.data());
            });
            setOrders(ordersArray)

        });
    }, [user.uid]);


    if (orders) {
        orderDisplay = (
            <Table style={{ fontSize: `.8rem`, borderBottom: `2px solid #FF2800` }} size="sm" striped bordered hover>
                <tbody>
                    {orders.map((order, index) => {

                        //Workaround while Brandon is h4x0r1ng
                        if(order.details){
                            return <tr key={index}><td>{order.details.create_time}</td>
                            {(order.details.purchase_units.map((unit, index) => {
                                return <td key={index}>{unit.items.map((item, index) => {
                                    console.log(item.name)
                                    return <span key={index}>{item.name} | </span>
                                })}</td>
                            })
                            )}
                        </tr>
                        }
                        else {
                            console.log("Some PayPal bits are funky")
                            return <p>Some PayPal bits are funky.</p>
                        }
                        

                    })}

                </tbody>
            </Table>
        )
    }



    return (
        orderDisplay
    )

}

export default OrdersTable;
