import React, { useState, useEffect } from 'react'
import { parse } from 'query-string'
import { firestore as db } from '../firebase.js'
import store from "../store.js"

import { Link } from "react-router-dom"
import styles from './Dashboard.module.css'

export default function ApprovalEmail({ location }) {
  let [displayText, setDisplayText] = useState('Please wait...')

  const { state } = store.use();
  let user = state.user

  console.log(user)

  useEffect(() => {
    const parsed = parse(location.search)
    console.log(parsed)
    let parsedPaypalEmail = parsed.e
    let flag = parsed.f

    if (flag === 'approveAll') {
      async function handleApproveAllNew() {
        const payeeRef = db.collection('payees').doc(parsedPaypalEmail)
        const payeeSnap = await payeeRef.get()
        const payee = payeeSnap.data()
        console.log(payee)
        payee.unapprovedSplits.map(async splitRef => {
          const splitSnap = await splitRef.get()
          const splitData = splitSnap.data()
          splitData.payees.map((payee, index) => {
            if (payee.paypalEmail === parsedPaypalEmail) {
              splitData.payees[index].approved = true
            }
            return null
          })
          await splitRef.set(splitData, { merge: true })
          console.log(splitRef)

        })
        payee.unapprovedSplits = []
        await payeeRef.set(payee, { merge: true })
        console.log(user)
        if (user) {
          let dynamicDashLink = `/~dash/?e=${parsedPaypalEmail}&u=${user.uid}`
          console.log(dynamicDashLink)
          setDisplayText(<h4>All splits approved! Please visit the <Link to={dynamicDashLink}>dashboard</Link> to review your splits</h4>)
        } else {
          setDisplayText(<h4>All splits approved! Please sign up/log in to review your splits</h4>)
        }

      }
      handleApproveAllNew()

    } else if (flag === 'review') {
      if (user) {
        let dynamicDashLink = `/~dash/?e=${parsedPaypalEmail}&u=${user.uid}`
        setDisplayText(<h4>Please proceed to the <Link to={dynamicDashLink}>dashboard</Link> to review your splits</h4>)
      } else {
        setDisplayText(<h4>Please sign up/log in to review your splits</h4>)
      }

    }
  }, [location, user])

  return <>
    <div className={styles['dash-container']}>
      <div className={styles['card-container']}>
        <div className={styles['main-card']}>
          {displayText}
        </div>
      </div>

    </div>
  </>
}