import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import firebaseApp, { firestore as db } from '../firebase.js'
import firebase from 'firebase/app'
import store from "../store.js"

import albumArtPlaceholder from '../images/album-art-placeholder.png'
import SpinnerOverlay from '../SpinnerOverlay'

import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faGuitar, faCompactDisc, faChevronDown, faPlayCircle, faPauseCircle, faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import ReactAudioPlayer from 'react-audio-player'
import styles from './Dashboard.module.css'

import ProjectModal from './modals/ProjectModal'
import ReleaseModal from './modals/ReleaseModal'
import SplitReviewModal from './modals/SplitReviewModal'

import { parse } from 'query-string'

var storage = firebaseApp.storage()

export default function Dashboard({ location, history }) {
  const [loading, setLoading] = useState(false)
  const [catalog, setCatalog] = useState([])
  const [claimAnother, setClaimAnother] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [projectModalShow, setProjectModalShow] = useState(false)
  const [releaseModalShow, setReleaseModalShow] = useState(false)
  const [splitReviewModalShow, setSplitReviewModalShow] = useState(false)
  const [updateCounter, setUpdateCounter] = useState(0)



  const { state } = store.use();
  let user = state.user



  useEffect(() => {
    async function fetchData() {
      setLoading(true)

      //FETCH PROJECT DATA
      let projectsRef = db.collection('projects')
      const snapshot = await projectsRef
        .where("managers", "array-contains", user.uid)
        .get()
      const catalog = snapshot.docs.map(project => {
        const data = project.data()
        data.slug = project.id
        const newData = {bio: data.bio,
                managers: data.managers, 
                name: data.name, 
                price: data.price,
                releases: data.releases,
                slug: data.slug,
                splitRef: data.splitRef}
        console.log(newData)
        return newData
      })

      let fullCatalog = catalog.map(async (project) => {
        try {
          const projectSplit = await project.splitRef.get()
          const projectSplitPayeeRefs = Object.values(projectSplit.data().payees)
          // console.log(projectSplitPayeeRefs)
          const newPayees = projectSplitPayeeRefs.map(async (newPayee) => {
            const payeeObject = await newPayee.payee.get()
            // console.log(payeeObject)
            const payeeObjectData = payeeObject.data()
            // console.log(payeeObjectData)
            return { name: payeeObjectData.name, payee: newPayee.payee, paypalEmail: payeeObjectData.paypalEmail, percent: newPayee.percent, approved: newPayee.approved ? newPayee.approved : false, splitRef: project.splitRef }
          })
          project.split = ({payees: await Promise.all(newPayees), refs: projectSplit.data().refs})
          console.log(project)
        } catch {
          console.error("No split found on project " + project.name)
        }



        const projectRef = db.collection('projects').doc(project.slug)
        //GET RELEASES
        const releasesRef = projectRef.collection('releases')
        let snapshot = await releasesRef.get()
        let releases = snapshot.docs.map(async doc => {
          var releaseRef = doc.ref
          var release = doc.data()
          var releasePath = doc.ref.path

          try {
            const releaseSplit = await release.splitRef.get()
            const releaseSplitPayeeRefs = Object.values(releaseSplit.data().payees)
            const newPayees = releaseSplitPayeeRefs.map(async (newPayee) => {
              const payeeObject = await newPayee.payee.get()
              const payeeObjectData = payeeObject.data()
              // console.log("Release payee data: " + payeeObjectData)
              return { name: payeeObjectData.name, paypalEmail: payeeObjectData.paypalEmail, percent: newPayee.percent }
            })
            release.split = {payees: (await Promise.all(newPayees)), refs: releaseSplitPayeeRefs}
            // console.log(release)

          } catch {
            console.error("No split found on release " + release.name)
          }

          async function getReleaseImage() {
            try {
              const releaseImageRef = storage.ref(['projects', project.slug, 'releases', release.slug, 'artwork', 'coverPhoto.jpg'].join('/'))
              let releaseImage = await releaseImageRef.getDownloadURL()
              release.image = releaseImage
            }
            catch (err) {
              console.log(err)
            }

          }
          await getReleaseImage()


          //GET WORKS
          var worksRef = releaseRef.collection('works').orderBy('trackNo')
          let snapshot = await worksRef.get()
          var works = snapshot.docs.map(async doc => {
            var work = doc.data()
            work.path = doc.ref.path
            work.releasePath = releasePath
            if (work.splitRef) {
              try {
                const workSplit = await work.splitRef.get()
                const workSplitPayeeRefs = Object.values(workSplit.data().payees)
                const newPayees = workSplitPayeeRefs.map(async newPayee => {
                  const payeeObject = await newPayee.payee.get()
                  const payeeObjectData = payeeObject.data()
                  // console.log("Track payee data: " + payeeObjectData)
                  return { name: payeeObjectData.name, paypalEmail: payeeObjectData.paypalEmail, percent: newPayee.percent }
                })
                work.split = {payees: (await Promise.all(newPayees)), refs: workSplitPayeeRefs}

              } catch  {
                console.error("No split found on work: " + work.name)
              }
            }

            return work
          })
          // console.log(await Promise.all(works))
          release.works = await Promise.all(works)
          return release
        })


        project.releases = await Promise.all(releases)

        return project
      })


      
      setCatalog(await Promise.all(fullCatalog))
      setLoading(false)
    }
    if (user && !projectModalShow.show && !releaseModalShow.show) {
      fetchData()
    }

  },  [user, projectModalShow, releaseModalShow, updateCounter, location])




  function Payee({ payee, claimedPayees, setClaimedPayees }) {
    const [approveAll, setApproveAll] = useState(payee.approveAll)
    const [totalUnapproved, setTotalUnapproved] = useState(0)
    // console.log(payee)
    // console.log(approveAll)

    useEffect(() => {
      function countTotalUnapproved() {
        // console.log(catalog)
        let count = 0
        const emails = claimedPayees.map(claimedPayee => claimedPayee.paypalEmail)
        try {
          catalog.forEach(project => {
            // console.log(project.split.refs.length)
            project.split.payees.forEach(payee => {
              
              if (!payee.approved && (emails.includes(payee.paypalEmail)) && project.split.refs.length > 0) {
                count++
              }
            })
          })
        } catch(error) {
          console.error("Error counting total splits: " + error)
        }
        // console.log(count)
        setTotalUnapproved(count)
        return count
      }

      countTotalUnapproved()
    })
    

    async function handleApproveAllFuture(e) {
      e.preventDefault()
      await db.collection('payees').doc(payee.paypalEmail).set({ approveAll: approveAll }, { merge: true })
    }

    async function handleApproveAllNew(e) {
      e.preventDefault()
      const emails = claimedPayees.map(claimedPayee => claimedPayee.paypalEmail)
      catalog.forEach(project => {
        // console.log(project)
        let updatedSplit = project.split
        // console.log(updatedSplit)
        updatedSplit.payees.forEach(async payee => {
          payee.approved = false
          if (!payee.approved && (emails.includes(payee.paypalEmail))) {
            payee.approved = true

            const payeeRef = db.collection('payees').doc(payee.paypalEmail)
            const payeeSnap = await payeeRef.get()
            const payeeData = payeeSnap.data()
            payeeData.unapprovedSplits = []
            payeeData.paypalEmail = payeeSnap.id
            payeeRef.set(payeeData, {merge: true})
          }
         


          let updatedSplitObject = {payees: updatedSplit}
          await payee.splitRef.set(updatedSplitObject, {merge: true})
        })}
        
      )
      setTotalUnapproved(0)
    }

    return (<div>
      {totalUnapproved > 0 ? <div style={{marginBottom: `2em`}}><div>
        You have {totalUnapproved} split(s) waiting for approval.
        </div><Button onClick={(e) => handleApproveAllNew(e)} className={styles['button-small-orange']}>APPROVE ALL</Button>
        <Button onClick={() => setSplitReviewModalShow( {show: true, payee: payee })} className={`${styles['button-fando']} ${styles['button-small']}`}>REVIEW SPLITS</Button>
        </div>: null}

      <Form onSubmit={(e) => handleApproveAllFuture(e)}>
        <Form.Check type="checkbox" checked={approveAll} onChange={() => setApproveAll(!approveAll)} label="Approve all new splits by default" />
        <Button type="submit" className={`${styles['button-fando']} ${styles['button-small']}`} >Save</Button>
      </Form>
    </div>)
  }

  function Claims() {
    const [claimedPayees, setClaimedPayees] = useState([])
    const [newPaypalEmail, setnewPaypalEmail] = useState()

    async function handlePaypalSubmit(e) {
      e.preventDefault()
      await db.collection('payees').doc(newPaypalEmail).set({ validating: { fandoUid: user.uid }, paypalEmail: newPaypalEmail }, { merge: true })
      setSubmitted(true)
    }

    useEffect(() => {

      const fetchClaims = (async () => {
        const parsed = parse(location.search)
        const payeesSnap = await db.collection('payees').where('fandoUid', '==', user.uid).get()
        const newClaimedPayees = []
  
        payeesSnap.docs.map(async payee => {
          const data = payee.data()
  
          try {
            const unapprovedSplitsSnap = await db.collection('payees').doc(data.paypalEmail).collection('unapprovedSplits').get()
  
            const unapprovedSplitRefs = await Promise.all(unapprovedSplitsSnap.docs.map(async split => {
              // console.log(split)
              return split.data().split
            }))
    
            const unapproved = await Promise.all(unapprovedSplitRefs)
            newClaimedPayees.push({...data, unapprovedSplitRefs: unapproved})
            console.log(newClaimedPayees)
            setClaimedPayees(newClaimedPayees)
          } catch(error) {
            console.log(error)
          }
          
        })
        try {
          if (user) {
            if (parsed?.u === user?.uid) {
              const payeeRef = db.collection('payees').doc(`${parsed.e}`)
              await payeeRef.update({validating: firebase.firestore.FieldValue.delete()})
              await payeeRef.set({ fandoUid: parsed.u, paypalEmail: parsed.e }, { merge: true })
              let payeeSnap = await payeeRef.get()
              let payeeData = payeeSnap.data()
    
              let payeeNotClaimed = newClaimedPayees.filter(payee => {
                return payee.fandoUid === parsed.e
              })
              // console.log(payeeNotClaimed)
              if (payeeNotClaimed.length > 0) {
                let updatedPayeeData = {...payeeData, fandoUid: parsed.u}
                newClaimedPayees.push(updatedPayeeData)
                let newUpdateCounter = updateCounter + 1
                setUpdateCounter(newUpdateCounter)
                setClaimedPayees(newClaimedPayees)
              }
            }
          }
        } catch(error) {
          console.error("Couldn't claim PayPal e-mail " + error)
        }
        console.log(newClaimedPayees)
      })
      fetchClaims()
    })

    return <>
      <div className={styles['payee-card']}>
        <div className={styles['payee-claims']}>
          <div>{claimedPayees.length > 1 ? "Paypal accounts claimed" : claimedPayees.length === 1 ? "Paypal account claimed:" : "No PayPal associated with this Fando account."}
            {claimedPayees.map(payee => {
              return <span style={{ fontWeight: `bold` }}> {payee.paypalEmail}</span>
            })}
          </div>
        </div>
        {claimedPayees.length > 0 ?
          claimedPayees.map(payee => {
            return <Payee 
                      payee={payee} 
                      claimedPayees={claimedPayees} 
                      setClaimedPayees={setClaimedPayees} />

          }) : null}

      </div>
      <div className={styles['claim-an-account']}>{!submitted && claimAnother ? <Form onSubmit={e => { handlePaypalSubmit(e) } }>
        <Form.Label>Claim PayPal account:</Form.Label>
        <Form.Control type="email" onChange={(e) => setnewPaypalEmail(e.target.value)} />
        <Button type="submit" className={styles['button-fando']}>Claim</Button>
      </Form> : submitted ? "Verification e-mail sent. Please click the link in e-mail to verify." : claimedPayees.length === 0 ? <Button className={`${styles['button-fando']} ${styles['button-small']}`} onClick={() => { setClaimAnother(true) } }>CLAIM A PAYPAL ACCOUNT</Button> : null}
      </div>
    </>
  }

  function SplitDisplay({ work, fullIndex }) {


    return (<>
      <div classname={styles['split-container']}>
        <div className={styles['split-dots']}>•	•</div>
      </div>
    </>)
  }

  function WorkRow({ release, work, fullIndex }) {
    const [songfileUrl, setSongfileUrl] = useState()
    const [rap, setRap] = useState()
    const [playing, setPlaying] = useState({ active: false })

    useEffect(() => {
      async function fetchData() {
        try {
          const songfileRef = storage.ref([work.releasePath, 'workFiles', work.slug, work.songFile].join('/'))
          const url = await songfileRef.getDownloadURL()
          setSongfileUrl(url)
        } catch (err) {
          console.error("SongFile not found for " + work.slug)
        }

      }

      if (work.songFile && work.songFile !== 'none') {
        fetchData()
      }
    }, [work.path, work.songFile, work.releasePath, work.slug])

    let playIcon = faPlayCircle

    if (rap && !playing.active) {
      rap.audioEl.pause()
      playIcon = faPlayCircle
    }

    if (playing) {
      if (rap && playing.active && (playing.key[0] !== fullIndex[0]) && (playing.key[1] !== fullIndex[1] && (playing.key[2] !== fullIndex[2]))) {
        rap.audioEl.pause()
        playIcon = faPlayCircle
      }

      if (rap && playing.active && (playing.key[0] === fullIndex[0]) && (playing.key[1] === fullIndex[1]) && (playing.key[2] === fullIndex[2])) {
        rap.audioEl.play()
        playIcon = faPauseCircle
      }
    }

    const handlePlayClick = () => {
      if ((playing.active && playing.key[0] !== fullIndex[0] && playing.key[1] !== fullIndex[1] && playing.key[2] === fullIndex[2]) || !playing.active) {
        setPlaying({ active: true, key: fullIndex })
      } else {
        setPlaying({ active: false })
      }
    }

    return (
      <div className={styles['work-row']}>
        <div className={styles['work-price']}>{fullIndex[2] + 1}</div>
        <div>
          <div className={styles['td-play-button']} onClick={handlePlayClick} >
            <ReactAudioPlayer src={songfileUrl} ref={element => setRap(element)} />
            <FontAwesomeIcon className={styles['play-button']} icon={playIcon} />
          </div>
        </div>
        <div>{work.title}</div>
        <div><SplitDisplay release={release} work={work} fullIndex={fullIndex} /></div>
        <div></div>
        <div></div>
        <div>${work.price ? work.price.toFixed(2) : null}</div>
      </div>)

  }

  function ReleaseRow({ project, release, index, projectIndex, parentCollapsed }) {
    const [collapsed, setCollapsed] = useState(true)
    let releaseIndex = index
    return (
      <Card className={styles['release-card']}>
        <div className={styles['release-art-container']}>
          <Image width="84px" src={release.image ? release.image : albumArtPlaceholder} alt="album art" onClick={() => setCollapsed(!collapsed)} />
        </div>
        <div className={styles['bold-release-text']} >
          <div onClick={() => setCollapsed(!collapsed)} >{release.name ? release.name : "(Untitled)"}</div>
          <Button onClick={() => { setReleaseModalShow({ show: true, project: project, release: release }) }} className={`${styles['button-fando']} ${styles['button-small']}`}>EDIT</Button>
        </div>
        <div className={styles['release-track-count']} onClick={() => setCollapsed(!collapsed)} >
          <div className={styles['chevron']} >
            <FontAwesomeIcon icon={faChevronDown} style={!collapsed ? { transform: `rotate(180deg)` } : null} />
          </div>
          {release.works.length} tracks
          </div>
        <Collapse in={!collapsed}>
          <div className={styles['work-list']}>
            <div className={`${styles['work-row']} ${styles['work-row-header']}`}>
              <div>#</div>
              <div>AUDIO</div>
              <div>TITLE</div>
              <div>SPLIT</div>
              <div></div>
              <div></div>
              <div>PRICE</div>
              <div></div>
            </div>
            {release.works.map((work, index) => {
              return <WorkRow
                release={release}
                work={work}
                key={[projectIndex, releaseIndex, index]}
                fullIndex={[projectIndex, releaseIndex, index]} />
            })}


          </div>
        </Collapse>
      </Card>
    )
  }

  function workSum(project) {
    let numWorks = 0
    if (project.releases) {
      project.releases.forEach(release => {
        numWorks = numWorks + release.works.length
      })
    }

    return numWorks
  }




  function ProjectRow({ project, index }) {
    const [collapsed, setCollapsed] = useState(true)
    let projectIndex = index


    return (<><div className={`${styles['project-row']} ${styles['border-top']}`}>
      <div className={styles['chevron']} onClick={() => setCollapsed(!collapsed)} >
        <FontAwesomeIcon icon={faChevronDown} style={!collapsed ? { transform: `rotate(180deg)` } : null} />
      </div>
      <div className={styles['bold-project-text']}>
        <div className={styles['project-text']} onClick={() => setCollapsed(!collapsed)} >{project.name}</div>
        <Button className={`${styles['button-fando']} ${styles['button-small']}`} onClick={() => setProjectModalShow({ show: true, project: project })}>EDIT</Button>
      </div>
      <div className={styles['project-row-text']}>{project.releases.length}</div>
      <div className={styles['project-row-text']}>{workSum(project)}</div>
      <div className={styles['project-row-text']}>
        <Link style={{ color: `grey` }} to={`/${project.slug}`}><FontAwesomeIcon icon={faShoppingBasket} /></Link>
      </div>
    </div>
      <Collapse in={!collapsed} >
        <div>
          {project.releases.map(release => {
            return <ReleaseRow project={project} release={release} parentCollapsed={collapsed} projectIndex={projectIndex} />
          })}
        </div>
      </Collapse></>)
  }




  return (<>
    {loading ? <SpinnerOverlay /> : null}
    <div className={styles['dash-container']}>
      <div className={styles['header-text']}>
        <h4>Welcome to Your Dashboard</h4>
      </div>
      <Claims />
      <div className={styles['card-container']}>
        <div className={styles['above-card-text']}>
          <FontAwesomeIcon icon={faUser} />
          <h4>Projects</h4>
          <Button className={styles['button-fando']} onClick={() => setProjectModalShow({ show: true })} >
            <FontAwesomeIcon className={styles['guitar-icon']} icon={faGuitar} />
            ADD PROJECT
            </Button>
          <Dropdown>
            <Dropdown.Toggle className={styles['button-fando']}>
              <FontAwesomeIcon className={styles['record-icon']} icon={faCompactDisc} />
                ADD RELEASE
              </Dropdown.Toggle>
            <Dropdown.Menu>
              {catalog.length > 0 ?
                catalog.map((project, index) => {
                  return (
                    <Dropdown.Item onClick={() => setReleaseModalShow({ project: project, show: true })}>{project.name}</Dropdown.Item>
                  )
                }) :
                <Dropdown.Item>Add Project</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card className={styles['main-card']}>
          <div className={`${styles['project-row']}`}>
            <div></div>
            <div className={styles['light-header-text']}>PROJECT NAME</div>
            <div className={styles['light-header-text']}>RELEASES</div>
            <div className={styles['light-header-text']}>TRACKS</div>
            <div className={styles['light-header-text']}>GO TO STORE</div>
          </div>
          <div className={styles['accordion-card']}>
            {catalog.map((project, index) => {
              return (<>
                <ProjectRow project={project} index={index} />
              </>
              )
            })}
          </div>
        </Card>
      </div>
      <ProjectModal
        user={user}
        releaseModalShow={releaseModalShow}
        setReleaseModalShow={setReleaseModalShow}
        project={projectModalShow.project ? projectModalShow.project : null}
        show={projectModalShow.show}
        setLoading={setLoading}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
        onHide={() => setProjectModalShow({ show: false })}
      />
      <ReleaseModal
        user={user}
        project={releaseModalShow.project}
        release={releaseModalShow.release}
        show={releaseModalShow.show}
        setLoading={setLoading}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
        onHide={() => setReleaseModalShow({ show: false })}
      />
      <SplitReviewModal 
        user={user}
        payee={splitReviewModalShow.payee}
        show={splitReviewModalShow.show}
        setLoading={setLoading}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
        onHide={() => setSplitReviewModalShow({ show: false })}
      />


    </div>
  </>
  )

  
}