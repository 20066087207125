import React, { useState, useEffect} from 'react';
import './custom.css';
import { BrowserRouter as Router } from "react-router-dom"
import { Route } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import firebaseApp from './firebase.js'

import Home from './Home.js'
import * as chrome from "./chrome.js"
import DevAdmin from './devadmin'
import Dashboard from './dashboard/Dashboard.js'
import ApprovalEmail from './dashboard/ApprovalEmail.js'
import Checkout from './Checkout.js'
import Profile from './profile/Profile'
import ProjectStore from './ProjectStore.js'
import Faq from './Faq'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfService from './TermsOfService'
import Contact from './Contact'
import Admin from './Admin'

import * as firebase from 'firebase/app'
import withFirebaseAuth from 'react-with-firebase-auth'
import store from "./store.js";

const firebaseAppAuth = firebaseApp.auth()
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
}

function UnAuthorized(){
  return (<h2 style={{textAlign: `center`, marginTop: `2em`}}> 503 Error: Unauthorized </h2>)
}

function PleaseSignIn() {
  return (<h2 style={{textAlign: `center`, marginTop: `2em`}}>Please Sign In</h2>)
}

function App({ user, signOut, signInWithGoogle, location, history }) {
  const [token, setToken] = useState(null)
  const {state, actions} = store.use()
  

  if(state.user === null && user){
    actions.user.setUser(user)
    token && actions.user.setToken(token)
  } else if(state.user && !user) {
    actions.user.setUser(null)
    console.log(token)
  }

  useEffect(() => {
    async function getToken(user) {
      try {
        let token = await user.getIdTokenResult()
        setToken(token)
      } catch (err) {
        console.error(err)
        setToken(null)
      }
    }

    if(user) {
      getToken(user)
    } else {
      setToken(null)
    }
  }, [user])

  let isAdmin = (token && token.claims.admin) || false
  let ProtectedDashboard = user ? Dashboard : PleaseSignIn
  let ProtectedAdmin = isAdmin ? Admin : UnAuthorized
  let devAdmin = process.env.NODE_ENV === 'development' ? <DevAdmin /> : null;

  return (
    <Router>
      <div className="App">
        {devAdmin}
        <chrome.Nav user={user} signOut={signOut}  signInWithGoogle={signInWithGoogle} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/checkout" render={(props) => <Checkout {...props} user={user} signInWithGoogle={signInWithGoogle} />} />
          <Route path="/~dash" component={ProtectedDashboard} />
          <Route path="/~approval-email" component={ApprovalEmail} />

          <Route path="/profile" render={props => <Profile {...props} user={user} />} />
          <Route path="/~faq" component={Faq} />
          <Route path="/~privacy-policy" component={PrivacyPolicy} />
          <Route path="/~terms-of-service" component={TermsOfService} />
          <Route path="/~contact" component={Contact} />
          <Route path="/~admin" component={ProtectedAdmin} />
          <Route path="/:projectSlug" component={ProjectStore} />
        </Switch> <chrome.Footer />
      </div>
    </Router>
  )
}

const WrappedApp = withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App)

export default WrappedApp;
